import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/layout/Layout";
import ContactsBanner from "../components/ui/banners/ContactsBanner";
import Seo from "../components/seo";
import SplitLayout from "../components/ui/banners/SplitLayout";
import ContentColumn from "../components/ui/ContentColumn";

const ChiSiamo = ({ pageContext }) => {
  const breadcrumbProps = {
    crumbs: pageContext.breadcrumb.crumbs,
    crumbLabel: "Chi siamo",
  };

  return (
    <>
      <Seo title="Chi siamo" description="Lorem ipsum dolor sit amet" />
      <Layout breadcrumbs={breadcrumbProps}>
        <StaticImage
          src="../assets/chi_siamo_banner.jpg"
          alt=""
          layout="fullWidth"
          placeholder="blurred"
          style={{ height: "500px" }}
        />

        <ContentColumn>
          <SplitLayout
            left={
              <h1 style={{ paddingTop: "50px" }}>L'istinto di proteggere</h1>
            }
            right={""}
          />

          <SplitLayout
            left={
              <p
                style={{
                  paddingRight: "70px",
                }}
              >
                Da sempre per Lysoform, la protezione è al primo posto. Perché è
                un istinto naturale, quello di prendersi cura delle persone che
                ami.
              </p>
            }
            right={
              <p
                style={{
                  paddingLeft: "70px",
                }}
              >
                Con Lysoform, promuoviamo la protezione attraverso la
                disinfezione in tutti i tipi di famiglie, ovunque ci sia
                quell’istinto di protezione.
              </p>
            }
          />

          <SplitLayout
            style={{
              padding: "30px 0 30px 0",
            }}
            left={
              <StaticImage
                src="../assets/chi_siamo_1.jpg"
                alt=""
                placeholder="blurred"
              />
            }
            right={
              <section
                style={{
                  paddingLeft: "70px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <h1>110 anni di protezione</h1>
                Il nome Lysoform nasce dalla contrazione di "LIquid SOap with
                FORMaldeyde", cioè un sapone ad alta capacità detergente formato
                da cresolo, con formaldeide, un potente principio battericida.
              </section>
            }
          />

          <SplitLayout
            style={{
              padding: "30px 0 30px 0",
            }}
            left={
              <p
                style={{
                  paddingRight: "70px",
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                Inizialmente utilizzato negli ospedali, Lysoform si è
                reinventato nel corso degli anni con prodotti sempre nuovi, che
                dalla cura della casa a quella della persona, hanno creato per i
                consumatori un’offerta completa nel mondo della protezione.
              </p>
            }
            right={
              <StaticImage
                src="../assets/chi_siamo_2.jpg"
                alt=""
                placeholder="blurred"
              />
            }
          />
        </ContentColumn>

        <ContactsBanner />
      </Layout>
    </>
  );
};

export default ChiSiamo;
