import * as React from "react";

import * as styles from "./SplitLayout.module.css";

type SplitLayoutProps = {
  left: React.ReactNode;
  right: React.ReactNode;
  style?: React.CSSProperties;
};

const SplitLayout = ({ left, right, style }: SplitLayoutProps) => {
  return (
    <div className={styles.container} style={style || {}}>
      <div className={styles.column}>{left}</div>
      <div className={styles.column}>{right}</div>
    </div>
  );
};

export default SplitLayout;
